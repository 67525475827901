import React from 'react';
import videos from "../videos.json";
import { VideoCard } from "./VideosCard";
import styles from "./VideosGrid.module.css";

export function VideosGrid() {
    return (
        <ul className={styles.videosGrid}>
            {videos.map((video)=>(
                <VideoCard key={video.id} video={video}/>
            ))}
        </ul>
    )
}