import React from 'react';
import styles from "./Menu.module.css";
import hamburger_icon from "../img/hamburger_icon.svg";
import close_icon from "../img/close_icon.svg";
import { Link } from "react-router-dom";

export function Menu(){
    return (
        <nav className={styles.topNav}>
            <button className={styles.openMenu} aria-label="open menu">
                <img src={hamburger_icon} alt="abrir menú" />
            </button>
            <ul className={styles.menu}>
                <button className={styles.closeMenu} aria-label="close menu">
                     <img src={close_icon} alt="cerrar menú" />
                </button>
                <li><Link className={styles.linkMenu} to={"/"}>HOME</Link></li>
                <li><Link className={styles.linkMenu} to ={"/aboutme/"}>ABOUT ME</Link></li>
                <li><Link className={styles.linkMenu} to ={"/videoworks/"}>VIDEO WORKS</Link></li>
                <li><Link className={styles.linkMenu} to ={"/contact/"}>CONTACT</Link></li>
            </ul>
        </nav>
    )
}