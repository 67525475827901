import React from 'react';
import styles from "./VideosCard.module.css";
import {Link} from "react-router-dom";


export function VideoCard({video}) {
    return( 
        <Link className={styles.videoCard} to={"/video/" + video.id}>
            <li className={styles.letterHover}>
                <img className={styles.videoImage} src={video.image} alt=""/>
                <div className={styles.videoTitle}>{video.title}</div>
            </li>
        </Link>
    );
}