import React from 'react';
import styles from "./AboutMe.module.css";
import gabriel_aboutme from "../img/gabriel_aboutme.jpg";


export function AboutMe() {
    return (
        <div className={styles.containerAbout}>
                <img
                    className={`${styles.col} ${styles.imageAbout}`}  
                    width={285} 
                    height={300} 
                    src = {gabriel_aboutme} alt="About me"
                />
                <div className={`${styles.col} ${styles.textAbout}`}>
  
                    <strong>Gabriel Torres Morandi - Physical theater and video arts director.</strong> 
                    <p>Venezuelan / Italian, currently living in Germany. </p>
                    <p>Studied physical theater at the <i>Folkwang Universität der Künste</i> in Essen, Germany.</p>
                    <p>From 2013 to 2014 Gabriel was the artistic director of the <i>Compañía Nacional de Teatro</i> 
                        de Venezuela (National Theater Company of Venezuela). During his time in the Company 
                        three documentaries were produced about his artistic and community work.</p>
                    <p>He was invited as a stage director to the <i>Chair Jerzy Grotowsky </i> in Caracas, Venezuela - to which 
                    renown directors as Eugenio Barba, Tony Cotts and Tomas Richard have been invited in the past - sharing 
                    with the participants one of his artistic and community works <i>“Chicharra”</i>.</p>
                    <p>Some of his works have been reviewed by philosopher José Manuel Briceño Guerrero.</p>
                    <p>Awarded the Venezuelan National Theater Award in 2011 for his work <i>“Caverna”</i>; 
                        awarded for best video-art with <i>“Androgynous Rhapsody”</i> and distinguished for his 
                        educational work at the Universidad Experimental de las Artes de Venezuela (Experimental 
                        University of the Arts of Venezuela) from the years 2015 to 2019.</p>
                    <p>His awarded creation <i>“Epifanía”</i> is currently being presented at different international film festivals.</p>
                    <p>Between the years 2021 and 2022 begins a series of artistic collaborations between Germany and 
                        Venezuela, directing and choreographing video-dance and video-art. At the end of 2022 he was invited,
                         as a playwright, in a production in the city of Frankfurt, Germany.</p>
                    <p>Highly involved with art education, Gabriel continually teaches a variety of workshops on artistic 
                        creation and interpretation in the field of contemporary performance.</p>
                    <p>Short review about Gabriel's work on Asociación Maestro J.M. Briceño Guerrero Magazine:</p>
                    <a className={styles.linkMagazine} href="https://www.jonuel-brigue.org/single-post/arte-22" target="_blank" rel="noopener noreferrer">"The cultural tsunami of stage artist Gabriel Torres Morandi"</a>
                </div>
               

        </div> 
    )
}
