import videos from "../videos.json";
import React from 'react';
import styles from "./VideoDetails.module.css";
import { useParams } from "react-router";
import {Link} from "react-router-dom";


export function VideoDetails(){
    const { videoId } = useParams();

    return( 
    <div className={styles.detailsContainer}>
        <div>
            <br></br>
            <iframe className={styles.videoContainer} src={videos[videoId].video} 
            title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
            <div className={styles.textDetails}>
                <p>
                    <strong>Title:</strong> {videos[videoId].title}
                </p>
                <p>
                    <strong>Description:</strong> {videos[videoId].description}
                </p>
            </div>
            <Link className={styles.videoBack}  to={"/videoworks/"}>
                <p>Back</p>
            </Link>
        </div>
    </div>
    )
}

