import styles from "./Contact.module.css";
import React, {useRef, useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert'; 


export function Contact() {
    const[formData, setFormData] = useState(
        {
            name: "",
            email: ""
        }
    )
    const [captchaValido, cambiarCaptchaValido] = useState(null);
    const captcha = useRef(null);
    const onChange = () =>{
        if (captcha.current.getValue()){
            cambiarCaptchaValido(true);
        }
        
    }
    const submit = (e) => {
        e.preventDefault();
        if (captcha.current.getValue()){
            cambiarCaptchaValido(true);
            let url = 'https://formspree.io/f/mwkjplep'
            fetch(url,
              {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  data: formData,
                })
              })
              swal({
                title: "Message sent",
                icon: "success",
                timer: "1200"
            });
            e.target.reset();
        }
        else {
            cambiarCaptchaValido(false);
        }
    }
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name] : event.target.value
        })
    }
    return (
        <div className={styles.contactContent}>
            <form id="form" className={styles.form} onSubmit={submit}>
                <label className = {styles.contactLabelName} for="name">Full name <span>*</span></label>
                <input className={styles.contactInputName} name="name" required type="text" id="name" placeholder="Pedro Perez" onChange={handleChange} ></input>
                <br></br>
                <label className={styles.contactLabelEmail} for="email">Email <span>*</span></label>
                <input className={styles.contactInputEmail} name="email" required type="email" id="email" placeholder="pedro@gmail.com" onChange={handleChange}></input>
                <br></br>
                <label className={styles.contactLabelTextArea} for="message">Message</label>
                <textarea className={styles.contactInputTextArea} id="message" name="message" cols="30" rows="8" onChange={handleChange}></textarea>
                <br></br>
                <div>
                    <ReCAPTCHA
                        ref={captcha}
                        sitekey="6LdFPd8jAAAAACn1SocruaUXVa54-Tk-RhmKNcbi"
                        onChange={onChange}
                    />
                </div>
            
                {captchaValido === false && <div className={styles.contactMensajeCaptcha}>Please accept the captcha</div>}
                <br></br>
                <button type="submit" className={styles.contactBtn}>Send Message</button>
            </form>
        </div>
    )    
}

