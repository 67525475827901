import React from 'react';
import styles from "./App.module.css";
import {Menu} from "./components/Menu"; 
import {
    HashRouter as Router,
    Routes,     
    Route,
    Link,
    Navigate,
  } from "react-router-dom";
import {AboutMe} from "./pages/AboutMe";
import {VideoWorks} from "./pages/VideoWorks";
import {Contact} from "./pages/Contact";
import { LandingPage } from "./pages/LandingPage";
import { VideoDetails } from "./pages/VideoDetails";


export function App(){
    return (
        <Router>  
            <header>
                <Link to="/" className={styles.primaryLink}>
                    <div className = {styles.artistName}>Gabriel Torres Morandi</div>
                </Link>
                <hr className={styles.menuLine}/>
                <div><Menu /></div>
                <hr className={styles.menuLine}/>
            </header>
            <main>            
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="aboutme/" element={<AboutMe />}/>
                    <Route path="videoworks/" element={<VideoWorks />}/>
                    <Route path="contact/" element={<Contact />}/>
                    <Route path="video/:videoId" element={<VideoDetails />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>    
            </main>
            <br></br>
            <br></br>
            <br></br>
            <footer className={styles.footer}>
                <a className={styles.linkFooter} href="https://jorgespinoza.com/" target="_blank" rel="noopener noreferrer">website developed by espinozadeveloper</a>
            </footer>
               
        </Router>      
    )
}