import React from 'react';
import { VideosGrid } from "../components/VideosGrid";
import styles from "./VideoWorks.module.css";


export function VideoWorks() {

    return (
        <div className={styles.titleVideoGrid}>
            <VideosGrid/>
        </div>  
    )
}