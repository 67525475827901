import React from 'react';
import videoHome from "../video/videoHome.mp4";
import styles from "./LandingPage.module.css";



export function LandingPage() {
    return(
        <div className={styles.imageContainer}>
                <video className={styles.videoCover} src={videoHome} autoPlay loop muted/>
        </div>        
    );
    
}